<template>
  <v-container
    fluid
    fill-height
  >
    <v-row class="flex-column flex-nowrap full-height pa-8">
      <v-col
        cols="auto"
        class="d-flex justify-center flex-shrink-1 no-overflow mb-8"
      >
        <slot name="image">
          <v-img
            class="full-height"
            contain
            :src="image"
          />
        </slot>
      </v-col>
      <v-col class="text-center text-h3">
        {{errorMessage}}
      </v-col>
      <v-col class="text-center">
        <div class="text-center">
          <span>{{detailMessage}}</span>
          <br />
          <span>{{$t("t.ErrorCode")}}</span><span class="primary--text font-weight-medium">{{errorCode}}</span>
        </div>
      </v-col>
      <v-col class="text-center">
        <v-btn
          color="primary"
          large
          rounded
          v-ripple
          @click="$router.push('/')"
        >
          {{$t("t.BackToDashboard")}}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  activated () {
    this.updateApplicationTitle()
  },
  methods: {
    updateApplicationTitle () {
      this.$store.commit('setModule', { name: this.applicationTitle })
    }
  },
  mounted () {
    this.updateApplicationTitle()
  },
  props: {
    applicationTitle: String,
    image: String,
    detailMessage: String,
    errorMessage: String,
    errorCode: String
  }
}
</script>

<style lang="stylus" scoped>
.full-height
  height 100%

.no-overflow
  overflow hidden
</style>
